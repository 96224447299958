import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Button, Typography, MenuItem, Select, FormControl, InputLabel, IconButton } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import config from '../config/apiConfig';

const PlayerList = () => {
    const [players, setPlayers] = useState([]);
    const [sportsCategories, setSportsCategories] = useState([]);
    const [sportsCategoryId, setSportsCategoryId] = useState('');
    const [loading, setLoading] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const token = localStorage.getItem('Authorization');
    const navigate = useNavigate();

    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10,
    });

    const fetchSportsCategories = async () => {
        try {
            const response = await axios.get(`${config.BASE_URL_SPORTS_MANAGEMENT_SERVICE}/sportsCategory`, {
                headers: {
                    Authorization: `${token}`,
                },
            });
            setSportsCategories(response.data.data?.sportsCategoryList || []);
        } catch (error) {
            console.error('Error fetching sports categories:', error);
            setSportsCategories([]);
        }
    };

    const fetchPlayers = async (currentPage = paginationModel.page, currentSize = paginationModel.pageSize) => {
        if (sportsCategoryId && token) {
            setLoading(true);
            try {
                const response = await axios.get(
                    `${config.BASE_URL_PLAYER_MANAGEMENT_SERVICE}/player?page=${currentPage}&size=${currentSize}&sportsCategoryId=${sportsCategoryId}`,
                    {
                        headers: {
                            Authorization: `${token}`,
                        },
                    }
                );
                const playerData = response.data.data ? response.data.data.playerList : [];
                setPlayers(Array.isArray(playerData) ? playerData : []);
                setTotalCount(response.data.data.totalCount || 0);
            } catch (error) {
                console.error('Error fetching players:', error);
                setPlayers([]);
            } finally {
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        fetchSportsCategories();
    }, [token]);

    useEffect(() => {
        if (sportsCategoryId) {
            fetchPlayers();
        }
    }, [sportsCategoryId, paginationModel, token]);

    const handlePaginationChange = (model) => {
        setPaginationModel(model);
        fetchPlayers(model.page, model.pageSize);
    };

    const handleView = (id) => {
        console.log('View player', id);
        navigate(`/players/${id}`);
    };

    const handleEdit = (playerData) => {
        const playerId = playerData.id;
        navigate(`/edit-player/${playerId}`, {
            state: { playerId:playerData.id  }
        });
    };

    const handleDelete = (id) => {
        console.log('Delete player', id);
        // Implement delete logic
    };

    const columns = [
        { field: 'id', headerName: 'Player ID', width: 150 },
        { field: 'userName', headerName: 'Player Name', width: 300 },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 150,
            sortable: false,
            renderCell: (params) => (
                <div>
                    <IconButton
                        color="primary"
                        onClick={() => handleView(params.row.id)}
                    >
                        <VisibilityIcon />
                    </IconButton>
                    <IconButton
                        color="secondary"
                        onClick={() => handleEdit(params.row)}
                    >
                        <EditIcon />
                    </IconButton>
                    <IconButton
                        color="error"
                        onClick={() => handleDelete(params.row.id)}
                    >
                        <DeleteIcon />
                    </IconButton>
                </div>
            ),
        },
    ];

    const rows = players.map((player) => ({
        id: player.id,
        userName: player.userName || 'No Name',
    }));

    const handleAddPlayerClick = () => {
        navigate('/add-player');
    };

    return (
        <Box sx={{ padding: 3 }}>
            <Typography variant="h4" gutterBottom>
                Players
            </Typography>

            <FormControl fullWidth sx={{ marginBottom: 3 }}>
                <InputLabel>Sports Category</InputLabel>
                <Select
                    value={sportsCategoryId}
                    onChange={(e) => setSportsCategoryId(e.target.value)}
                    label="Sports Category"
                >
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                    {sportsCategories.map((category) => (
                        <MenuItem key={category.id} value={category.id}>
                            {category.categoryName}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <Button
                variant="contained"
                color="primary"
                onClick={handleAddPlayerClick}
            >
                Add Player
            </Button>

            <Box sx={{ height: 500, width: '100%', marginTop: 3 }}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    paginationModel={paginationModel}
                    rowCount={totalCount}
                    pagination
                    pageSizeOptions={[5, 10, 20]}
                    paginationMode="server"
                    onPaginationModelChange={handlePaginationChange}
                    loading={loading}
                />
            </Box>
        </Box>
    );
};

export default PlayerList;
