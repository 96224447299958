import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Button, TextField, Typography, MenuItem, Select, InputLabel, FormControl, FormHelperText } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import config from '../config/apiConfig';
import AsyncSelect from 'react-select/async';


const TeamForm = () => {
    const [isUploading, setIsUploading] = useState(false);
    const [sportsCategories, setSportsCategories] = useState([]);
    const [players, setPlayers] = useState([]);
    const [selectedPlayers, setSelectedPlayers] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const token = localStorage.getItem('Authorization');

    const initialValues = {
        teamName: '',
        coachName: '',
        country: '',
        sportsCategoryId: '',
        playersIds: [],
        profileImage: null,
    };

    const validationSchema = Yup.object().shape({
        teamName: Yup.string().required('Team name is required'),
        coachName: Yup.string().required('Coach name is required'),
        country: Yup.string().required('Country is required'),
        sportsCategoryId: Yup.string().required('Sports category ID is required'),
        playersIds: Yup.array().min(1, 'Select at least one player'),
        profileImage: Yup.mixed().required('Profile picture is required'),
    });

    useEffect(() => {
        const fetchSportsCategories = async () => {
            try {
                const response = await axios.get(`${config.BASE_URL_SPORTS_MANAGEMENT_SERVICE}/sportsCategory`, {
                    headers: { Authorization: `${token}` },
                });
                setSportsCategories(response.data.data?.sportsCategoryList || []);
            } catch (error) {
                console.error('Error fetching sports categories:', error);
            }
        };

        fetchSportsCategories();
    }, [token]);

    const fetchPlayers = async (sportsCategoryId) => {
        if (sportsCategoryId && token) {
            setLoading(true);
            try {
                const response = await axios.get(
                    `${config.BASE_URL_PLAYER_MANAGEMENT_SERVICE}/player?sportsCategoryId=${sportsCategoryId}`,
                    {
                        headers: { Authorization: `${token}` },
                    }
                );
                const playerData = response.data.data?.playerList || [];
                setPlayers(playerData);
                setSelectedPlayers([]); // Reset selected players when category changes
            } catch (error) {
                console.error('Error fetching players:', error);
                setPlayers([]);
            } finally {
                setLoading(false);
            }
        }
    };


    const getPresignedUrl = async (fileType) => {
        try {
            const response = await axios.post(
                `${config.BASE_URL_ASSET_MANAGEMENT_SERVICE}/api/v1/asset/getPreSignedUrl`,
                {
                    fileType: fileType.toUpperCase(),
                    isProtected: false,
                    entityType: 'TEAM',
                },
                {
                    headers: {
                        Authorization: `${token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            return response.data.data.preSignedUrl;
        } catch (error) {
            console.error('Error getting pre-signed URL:', error);
            return null;
        }
    };

    const uploadToS3 = async (url, file) => {
        try {
            await axios.put(url, file, {
                headers: {
                    'x-amz-acl': 'public-read',
                    'Content-Type': file.type,
                },
            });
            return url.split('?')[0];
        } catch (error) {
            console.error('Error uploading file:', error);
            return null;
        }
    };

    const handleAddTeam = async (values) => {
        const { profileImage, ...teamData } = values;

        if (!profileImage) {
            alert('Please select a profile image');
            return;
        }

        setIsUploading(true);

        const presignedUrl = await getPresignedUrl(profileImage.type.split('/')[1]);
        if (!presignedUrl) {
            setIsUploading(false);
            alert('Failed to get presigned URL');
            return;
        }

        const uploadedImageUrl = await uploadToS3(presignedUrl, profileImage);
        if (!uploadedImageUrl) {
            setIsUploading(false);
            alert('Failed to upload profile image');
            return;
        }

        const teamPayload = {
            ...teamData,
            profileImage: {
                assetTitle: 'Team profile picture',
                assetDescription: 'Team profile picture',
                fileLink: uploadedImageUrl,
                fileUuid: profileImage.name,
                fileType: profileImage.type.split('/')[1].toUpperCase(),
                isProtected: false,
            },
        };

        try {
            const response = await axios.post(`${config.BASE_URL_TEAM_MANAGEMENT_SERVICE}/team`, teamPayload, {
                headers: {
                    Authorization: `${token}`,
                    'Content-Type': 'application/json',
                },
            });
            if (response.status === 200) {
                navigate('/Dashboard', { state: { tabValue: 1 } });
                alert('Team added successfully!');
            }
        } catch (error) {
            console.error('Error adding team:', error);
            alert('Failed to add team');
        }
        setIsUploading(false);
    };


    return (
        <Box sx={{ padding: 3 }}>
            <Typography variant="h4" gutterBottom>
                Add Team
            </Typography>

            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleAddTeam}
            >
                {({ errors, touched, setFieldValue, values }) => (
                    <Form>
                        <Field
                            as={TextField}
                            name="teamName"
                            label="Team Name"
                            fullWidth
                            error={touched.teamName && !!errors.teamName}
                            helperText={touched.teamName && errors.teamName}
                            sx={{ mb: 2 }}
                        />
                        <Field
                            as={TextField}
                            name="coachName"
                            label="Coach Name"
                            fullWidth
                            error={touched.coachName && !!errors.coachName}
                            helperText={touched.coachName && errors.coachName}
                            sx={{ mb: 2 }}
                        />
                        <Field
                            as={TextField}
                            name="country"
                            label="Country"
                            fullWidth
                            error={touched.country && !!errors.country}
                            helperText={touched.country && errors.country}
                            sx={{ mb: 2 }}
                        />
                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <InputLabel id="asd">Sports Category</InputLabel>
                            <Select
                                name="sportsCategoryId"
                                value={values.sportsCategoryId}
                                onChange={(e) => {
                                    const selectedCategory = e.target.value;
                                    setFieldValue('sportsCategoryId', selectedCategory);
                                    fetchPlayers(selectedCategory); // Fetch players dynamically
                                }}
                                error={touched.sportsCategoryId && !!errors.sportsCategoryId}
                            >
                                {sportsCategories.map((category) => (
                                    <MenuItem key={category.id} value={category.id}>
                                        {category.categoryName}
                                    </MenuItem>
                                ))}
                            </Select>
                            {touched.sportsCategoryId && errors.sportsCategoryId && (
                                <FormHelperText error>{errors.sportsCategoryId}</FormHelperText>
                            )}
                        </FormControl>

                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <AsyncSelect
                                isMulti
                                cacheOptions
                                placeholder={"Players"}
                                loadOptions={async (inputValue) => {
                                    if (!inputValue.trim()) return [];
                                    try {
                                        const response = await axios.get(
                                            `${config.BASE_URL_PLAYER_MANAGEMENT_SERVICE}/player/search?sportsCategoryId=${values.sportsCategoryId}&playerName=${inputValue}`,
                                            {
                                                headers: { Authorization: `${token}` },
                                            }
                                        );
                                        const playersData = response.data.data?.playerList || [];
                                        return playersData.map((player) => ({
                                            value: player.id,
                                            label: `${player.firstName} ${player.lastName}`,
                                            player,
                                        }));
                                    } catch (error) {
                                        console.error('Error fetching players:', error);
                                        return [];
                                    }
                                }}
                                defaultOptions={players.map((player) => ({
                                    value: player.id,
                                    label: `${player.firstName} ${player.lastName}`,
                                    player,
                                }))}
                                value={selectedPlayers.map((player) => ({
                                    value: player.id,
                                    label: `${player.firstName} ${player.lastName}`,
                                }))}
                                onChange={(selectedOptions) => {
                                    const selectedPlayersData = selectedOptions.map((option) => option.player || players.find((p) => p.id === option.value));
                                    const playerIds = selectedPlayersData.map((player) => player.id);

                                    // Update the players array to include newly selected players
                                    const newPlayers = selectedPlayersData.filter(
                                        (newPlayer) => !players.some((existingPlayer) => existingPlayer.id === newPlayer.id)
                                    );

                                    setPlayers([...players, ...newPlayers]); // Update the players array
                                    setSelectedPlayers(selectedPlayersData); // Save selected players
                                    setFieldValue('playersIds', playerIds); // Save IDs for API
                                }}
                            />
                            {touched.playersIds && errors.playersIds && (
                                <FormHelperText error>{errors.playersIds}</FormHelperText>
                            )}
                        </FormControl>

                        <input
                            type="file"
                            name="profileImage"
                            onChange={(event) => setFieldValue('profileImage', event.currentTarget.files[0])}
                        />
                        {errors.profileImage && touched.profileImage && (
                            <div style={{ color: 'red' }}>{errors.profileImage}</div>
                        )}

                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={isUploading}
                            sx={{ mt: 2 }}
                        >
                            {isUploading ? 'Uploading...' : 'Add Team'}
                        </Button>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};

export default TeamForm;