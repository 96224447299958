// src/components/EventForm.js
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import config from '../config/apiConfig';

const EventForm = () => {
    const { register, handleSubmit, setValue, reset } = useForm();
    const { id } = useParams();
    const location = useLocation();
    const token = location.state?.authToken || localStorage.getItem('Authorization');
    const { parentEventId, eventId } = location.state || {};
    const navigate = useNavigate();

    useEffect(() => {
        const fetchEvent = async () => {
            if (eventId) {
                try {
                    
                    const response = axios.get(`${config.BASE_URL_SPORTS_MANAGEMENT_SERVICE}/event/updateEvent`, {
                        params: { eventId },
                        headers: { 'Authorization': token },
                    });

                    if (response.status === 200) {
                        const finalData = response.data.data;
                        Object.keys(finalData).forEach(key => {
                            if (finalData[key]) setValue(key, finalData[key]);
                        });
                    } else {
                        console.error('Failed to fetch event data:', response.status);
                    }
                } catch (error) {
                    console.error('An error occurred while fetching event data:', error);
                }
            }
        };

        fetchEvent();
    }, [eventId, token, setValue]);

    const onSubmit = async (data) => {
        const url = `${config.BASE_URL_SPORTS_MANAGEMENT_SERVICE}/event`;
        const method = eventId ? 'PUT' : 'POST';
        data.eventStartDate = moment(data.eventStartDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
        data.eventEndDate = moment(data.eventEndDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ');

        const response = await axios({
            url: url,
            method: method,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
            data: {
                ...data,
                parentEventId: parentEventId || null
            }
        });
        console.log(response);
        if (response.status === 200 && response.data.code === "SP-00") {
            navigate('/Dashboard', { state: { tabValue: 0 } });
            alert('Event saved successfully!');
            reset();
        } else {
            alert('Failed to save event');
        }
    };

    // Enhanced CSS styles
    const formStyles = {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '20px',
        maxWidth: '1200px',
        margin: '0 auto',
        marginTop: 40,
        padding: '20px',
        border: '1px solid #ddd',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        backgroundColor: '#f9f9f9',
    };

    const columnStyles = {
        flex: '1 1 45%',
        display: 'flex',
        flexDirection: 'column',
    };

    const inputGroupStyles = {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '16px',
    };

    const labelStyles = {
        fontSize: '14px',
        color: '#333',
        marginBottom: '4px',
        fontWeight: 'bold',
    };

    const inputStyles = {
        padding: '8px',
        borderRadius: '4px',
        border: '1px solid #ccc',
        fontSize: '14px',
        backgroundColor: '#fff',
    };

    const buttonStyles = {
        gridColumn: 'span 2',
        padding: '10px 20px',
        backgroundColor: '#4CAF50',
        color: 'white',
        fontWeight: 'bold',
        fontSize: '16px',
        borderRadius: '4px',
        cursor: 'pointer',
        border: 'none',
        alignSelf: 'center',
    };

    return (
        <>
            <h1 style={formStyles}>Add Event</h1>
            <form onSubmit={handleSubmit(onSubmit)} style={formStyles}>
                <div style={columnStyles}>
                    <div style={inputGroupStyles}>
                        <label style={labelStyles}>Event Name:</label>
                        <input {...register('eventName')} style={inputStyles} />
                    </div>
                    <div style={inputGroupStyles}>
                        <label style={labelStyles}>Event Start Date:</label>
                        <input type="datetime-local" {...register('eventStartDate')} style={inputStyles} />
                    </div>
                    <div style={inputGroupStyles}>
                        <label style={labelStyles}>Event End Date:</label>
                        <input type="datetime-local" {...register('eventEndDate')} style={inputStyles} />
                    </div>
                    <div style={inputGroupStyles}>
                        <label style={labelStyles}>Type:</label>
                        <input {...register('type')} style={inputStyles} />
                    </div>
                    <div style={inputGroupStyles}>
                        <label style={labelStyles}>Location:</label>
                        <input {...register('location')} style={inputStyles} />
                    </div>
                    <div style={inputGroupStyles}>
                        <label style={labelStyles}>Description:</label>
                        <textarea {...register('description')} style={inputStyles} />
                    </div>
                    <div style={inputGroupStyles}>
                        <label style={labelStyles}>Pay Per View Price:</label>
                        <input type="number" step="0.01" {...register('payPerViewPrice', { valueAsNumber: true })} style={inputStyles} defaultValue="0.00" min="0"/>
                    </div>
                    {/* Thumbnail Image 1 */}
                    <div style={inputGroupStyles}>
                        <label style={labelStyles}>Thumbnail Image 1 Description:</label>
                        <input {...register('thumbnailImage1.assetDescription')} style={inputStyles} />
                    </div>
                    <div style={inputGroupStyles}>
                        <label style={labelStyles}>Thumbnail Image 1 UUID:</label>
                        <input {...register('thumbnailImage1.fileUuid')} style={inputStyles} />
                    </div>
                    <div style={inputGroupStyles}>
                        <label style={labelStyles}>Thumbnail Image 1 File Type:</label>
                        <input {...register('thumbnailImage1.isProtected')} style={inputStyles} />
                    </div>

                    {/* Thumbnail Image 2 */}
                    <div style={inputGroupStyles}>
                        <label style={labelStyles}>Thumbnail Image 2 Description:</label>
                        <input {...register('thumbnailImage2.assetDescription')} style={inputStyles} />
                    </div>
                    <div style={inputGroupStyles}>
                        <label style={labelStyles}>Thumbnail Image 2 UUID:</label>
                        <input {...register('thumbnailImage2.fileUuid')} style={inputStyles} />
                    </div>
                    <div style={inputGroupStyles}>
                        <label style={labelStyles}>Thumbnail Image 2 File Type:</label>
                        <input {...register('thumbnailImage2.isProtected')} style={inputStyles} />
                    </div>

                    {/* Banner */}
                    <div style={inputGroupStyles}>
                        <label style={labelStyles}>Banner Description:</label>
                        <input {...register('bannerImage.assetDescription')} style={inputStyles} />
                    </div>
                    <div style={inputGroupStyles}>
                        <label style={labelStyles}>Banner UUID:</label>
                        <input {...register('bannerImage.fileUuid')} style={inputStyles} />
                    </div>
                    <div style={inputGroupStyles}>
                        <label style={labelStyles}>Banner File Type:</label>
                        <input {...register('bannerImage.isProtected')} style={inputStyles} />
                    </div>
                    <div style={inputGroupStyles}>
                        <label style={labelStyles}>Banner hashtags:</label>
                        <input {...register('bannerImage.hashtags')} style={inputStyles} />
                    </div>

                </div>
                <div style={columnStyles}>
                    <div style={inputGroupStyles}>
                        <label style={labelStyles}>Streaming Platform:</label>
                        <textarea {...register('streamingPlatform')} style={inputStyles} />
                    </div>
                    <div style={inputGroupStyles}>
                        <label style={labelStyles}>Event Status:</label>
                        <textarea {...register('eventStatus')} style={inputStyles} />
                    </div>
                    <div style={inputGroupStyles}>
                        <label style={labelStyles}>Sports Category ID:</label>
                        <input type="number" {...register('sportsCategory')} style={inputStyles} />
                    </div>
                    <div style={inputGroupStyles}>
                        <label style={labelStyles}>Streaming URL:</label>
                        <input {...register('streamingUrl')} style={inputStyles} />
                    </div>
                    <div style={inputGroupStyles}>
                        <label style={labelStyles}>Recorded URL:</label>
                        <input {...register('recordedUrl')} style={inputStyles} />
                    </div>
                    <div style={inputGroupStyles}>
                        <label style={labelStyles}>Show Banner:</label>
                        <select {...register('showBanner')} style={inputStyles}>
                            <option value="true">True</option>
                            <option value="false">False</option>
                        </select>
                    </div>

                    {/* Thumbnail Image 1 */}
                    <div style={inputGroupStyles}>
                        <label style={labelStyles}>Thumbnail Image 1 Title:</label>
                        <input {...register('thumbnailImage1.assetTitle')} style={inputStyles} />
                    </div>
                    <div style={inputGroupStyles}>
                        <label style={labelStyles}>Thumbnail Image 1 File Link:</label>
                        <input {...register('thumbnailImage1.fileLink')} style={inputStyles} />
                    </div>
                    <div style={inputGroupStyles}>
                        <label style={labelStyles}>Thumbnail Image 1 File Type:</label>
                        <input {...register('thumbnailImage1.fileType')} style={inputStyles} />
                    </div>
                    <div style={inputGroupStyles}>
                        <label style={labelStyles}>Thumbnail Image 1 hashtags:</label>
                        <input {...register('thumbnailImage1.hashtags')} style={inputStyles} />
                    </div>

                    {/* Thumbnail Image 2 */}
                    <div style={inputGroupStyles}>
                        <label style={labelStyles}>Thumbnail Image 2 Title:</label>
                        <input {...register('thumbnailImage2.assetTitle')} style={inputStyles} />
                    </div>
                    <div style={inputGroupStyles}>
                        <label style={labelStyles}>Thumbnail Image 2 File Link:</label>
                        <input {...register('thumbnailImage2.fileLink')} style={inputStyles} />
                    </div>
                    <div style={inputGroupStyles}>
                        <label style={labelStyles}>Thumbnail Image 2 File Type:</label>
                        <input {...register('thumbnailImage2.fileType')} style={inputStyles} />
                    </div>
                    <div style={inputGroupStyles}>
                        <label style={labelStyles}>Thumbnail Image 2 hashtags:</label>
                        <input {...register('thumbnailImage2.hashtags')} style={inputStyles} />
                    </div>

                    {/* Banner Image */}
                    <div style={inputGroupStyles}>
                        <label style={labelStyles}>Banner Title:</label>
                        <input {...register('bannerImage.assetTitle')} style={inputStyles} />
                    </div>
                    <div style={inputGroupStyles}>
                        <label style={labelStyles}>Banner File Link:</label>
                        <input {...register('bannerImage.fileLink')} style={inputStyles} />
                    </div>
                    <div style={inputGroupStyles}>
                        <label style={labelStyles}>Banner File Type:</label>
                        <input {...register('bannerImage.fileType')} style={inputStyles} />
                    </div>


                </div>
                <button type="submit" style={buttonStyles}>Submit</button>
            </form>
        </>
    );
};

export default EventForm;
