import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Typography, MenuItem, Select, FormControl, InputLabel, IconButton } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import config from '../config/apiConfig';

const TeamList = () => {
    const [teams, setTeams] = useState([]);
    const [sportsCategories, setSportsCategories] = useState([]);
    const [sportsCategoryId, setSportsCategoryId] = useState('');
    const [loading, setLoading] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const token = localStorage.getItem('Authorization');
    const navigate = useNavigate();

    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10,
    });

    // Fetch sports categories
    const fetchSportsCategories = async () => {
        try {
            const response = await axios.get(`${config.BASE_URL_SPORTS_MANAGEMENT_SERVICE}/sportsCategory`, {
                headers: {
                    Authorization: `${token}`,
                },
            });
            const categories = response.data.data?.sportsCategoryList || [];
            setSportsCategories(categories);
        } catch (error) {
            console.error('Error fetching sports categories:', error);
            setSportsCategories([]);
        }
    };

    // Fetch teams
    const fetchTeams = async (currentPage = paginationModel.page, currentPageSize = paginationModel.pageSize) => {
        if (sportsCategoryId) {
            setLoading(true);
            try {
                const response = await axios.get(
                    `${config.BASE_URL_TEAM_MANAGEMENT_SERVICE}/team?sportsCategoryId=${sportsCategoryId}&page=${currentPage}&size=${currentPageSize}`,
                    {
                        headers: {
                            Authorization: `${token}`,
                        },
                    }
                );

                const teamData = response.data.data ? response.data.data.teams : [];
                setTeams(Array.isArray(teamData) ? teamData : []);
                setTotalCount(response.data.data.totalCount || 0);
            } catch (error) {
                console.error('Error fetching teams:', error);
                setTeams([]);
            } finally {
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        fetchSportsCategories(); // Load sports categories on component mount
    }, [token]);

    useEffect(() => {
        if (sportsCategoryId) {
            fetchTeams();
        }
    }, [sportsCategoryId, paginationModel, token]);

    const handlePaginationChange = (model) => {
        setPaginationModel(model);
        fetchTeams(model.page, model.pageSize);
    };

    const handleAddNewTeam = () => {
        navigate('/add-team');
    };

    // Action handlers
    const handleView = (id) => {
        navigate(`/teams/${id}`);
    };

    const handleEdit = (teamData) => {
        navigate(`/edit-team/${teamData.id}`, { state: { authToken: token, teamData } });
    };

    const handleDelete = (id) => {
        console.log('Delete team', id);
        // Implement your delete logic here
    };

    const handleAddTeamClick = () => {
        navigate('/add-team');
    };

    const columns = [
        { field: 'id', headerName: 'Team ID', width: 150 },
        { field: 'teamName', headerName: 'Team Name', width: 300 },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 150,
            sortable: false,
            renderCell: (params) => (
                <div>
                    <IconButton color="primary" onClick={() => handleView(params.row.id)}>
                        <VisibilityIcon />
                    </IconButton>
                    <IconButton color="secondary" onClick={() => handleEdit(params.row)}>
                        <EditIcon />
                    </IconButton>
                    <IconButton color="error" onClick={() => handleDelete(params.row.id)}>
                        <DeleteIcon />
                    </IconButton>
                </div>
            ),
        },
    ];

    const rows = teams.map((team) => ({
        id: team.id,
        teamName: team.teamName,
    }));

    return (
        <Box sx={{ padding: 3 }}>
            <Typography variant="h4" gutterBottom>
                Teams
            </Typography>

            <FormControl fullWidth sx={{ marginBottom: 3 }}>
                <InputLabel>Sports Category</InputLabel>
                <Select
                    value={sportsCategoryId}
                    onChange={(e) => setSportsCategoryId(e.target.value)}
                    label="Sports Category"
                >
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                    {sportsCategories.map((category) => (
                        <MenuItem key={category.id} value={category.id}>
                            {category.categoryName}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <Button
                variant="contained"
                color="primary"
                onClick={handleAddTeamClick}
            >
                Add Team
            </Button>


            <Box sx={{ height: 500, width: '100%', marginTop: 3 }}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    paginationModel={paginationModel}
                    rowCount={totalCount}
                    pagination
                    pageSizeOptions={[5, 10, 20]}
                    paginationMode="server"
                    onPaginationModelChange={handlePaginationChange}
                    loading={loading}
                />
            </Box>
        </Box>
    );
};

export default TeamList;
