import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import EventForm from './EventForm';
import config from '../config/apiConfig';
import axios from 'axios';
import {
    Box, Button, Typography, Paper, IconButton, Pagination
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const EventDetail = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [event, setEvent] = useState(null);
    const [showChildForm, setShowChildForm] = useState(false);
    const [expandedRounds, setExpandedRounds] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [fixturesPerPage] = useState(10);
    const token = localStorage.getItem('Authorization');

    useEffect(() => {
        if (token) {
            axios.get(`${config.BASE_URL_SPORTS_MANAGEMENT_SERVICE}/event?withAttributes=false&eventId=${id}`, {
                headers: {
                    Authorization: `${token}`
                }
            })
            .then(response => {
                setEvent(response.data.data.eventList[0]);
            })
            .catch(error => {
                console.error('Error fetching events:', error);
            });
        }
    }, [id]);

    const handleRoundToggle = (roundId) => {
        setExpandedRounds(prevState => ({
            ...prevState,
            [roundId]: !prevState[roundId]
        }));
    };

    const handlePageChange = (event, page) => {
        setCurrentPage(page);
    };

    // const handleEdit = (eventData) => {
    //     navigate(`/edit-event/${eventData.id}`, { 
    //         state: { 
    //             authToken: token, 
    //             parentEventId: eventData.parentEventId,
    //             eventId: eventData.id 
    //         } 
    //     });
    // };

    const columns = [
        { field: 'id', headerName: 'ID', width: 100 },
        { field: 'eventName', headerName: 'Event Name', width: 200 },
        { field: 'type', headerName: 'Type', width: 150 },
        { field: 'description', headerName: 'Description', width: 300 },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 150,
            sortable: false,
            renderCell: (params) => (
                <div>
                    <IconButton
                        color="primary"
                        onClick={() => handleView(params.row.id)}
                    >
                        <VisibilityIcon />
                    </IconButton>
                    <IconButton
                        color="secondary"
                        onClick={() => handleEdit(params.row)}
                    >
                        <EditIcon />
                    </IconButton>
                    <IconButton
                        color="error"
                        onClick={() => handleDelete(params.row.id)}
                    >
                        <DeleteIcon />
                    </IconButton>
                </div>
            ),
        },
    ];

    const handleView = (id) => {
        setEvent(null);
        navigate(`/events/${id}`);
    };

    const handleEdit = (eventData) => {
        navigate(`/edit-event/${eventData.id}`, { 
            state: { 
                authToken: token, 
                parentEventId: eventData.parentEventId,
                eventId: eventData.id 
            } 
        });
        // Implement your edit logic here
    };

    const handleDelete = (id) => {
        console.log('Delete event', id);
        // Implement your delete logic here
    };
    const renderFixtures = (fixtures) => {
        const indexOfLastFixture = currentPage * fixturesPerPage;
        const indexOfFirstFixture = indexOfLastFixture - fixturesPerPage;
        const currentFixtures = fixtures.slice(indexOfFirstFixture, indexOfLastFixture);

        return (
            <Box sx={{ height: 400, width: '100%', mt: 2 }}>
                <DataGrid
                    rows={currentFixtures}
                    columns={columns}
                    pageSize={fixturesPerPage}
                    pagination
                    rowsPerPageOptions={[5, 10, 15]}
                    disableSelectionOnClick
                />
                <Pagination
                    count={Math.ceil(fixtures.length / fixturesPerPage)}
                    page={currentPage}
                    onChange={handlePageChange}
                    sx={{ mt: 2, mb: 2 }}
                />
            </Box>
        );
    };

    return (
        <Box sx={{ padding: 3 }}>
            {event ? (
                <Box>
                    <Paper elevation={3} sx={{ padding: 3, mb: 3, borderRadius: 2 }}>
                        <Typography variant="h4" gutterBottom>
                            {event.eventName} ({event.type})
                        </Typography>
                        <Typography variant="body1" sx={{ mb: 2 }}>
                            {event.description || 'No description available'}
                        </Typography>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => setShowChildForm(!showChildForm)}
                            sx={{ mb: 2 }}
                        >
                            Add Child Event
                        </Button>
                        {showChildForm && (
                            <Box sx={{ mt: 2 }}>
                                <EventForm authToken={token} parentEventId={id} eventId={null} />
                            </Box>
                        )}
                    </Paper>

                    {event.subEventList && event.subEventList.length > 0 ? (
                        <Paper elevation={2} sx={{ padding: 2, borderRadius: 2 }}>
                            <Typography variant="h5" gutterBottom>
                                Sub Events
                            </Typography>
                            {renderFixtures(event.subEventList)}
                        </Paper>
                    ) : (
                        <Typography variant="body1" color="textSecondary" sx={{ mt: 3 }}>
                            No sub-events available.
                        </Typography>
                    )}
                </Box>
            ) : (
                <Typography variant="body1" color="textSecondary">
                    Loading event details...
                </Typography>
            )}
        </Box>
    );
};

export default EventDetail;