import axios from 'axios';
import config from '../config/apiConfig';
import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { IconButton } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';

const EventList = ({ authToken }) => {
    const [events, setEvents] = useState([]);
    const token = localStorage.getItem('Authorization');
    const navigate = useNavigate(); // Hook for navigation

    const columns = [
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'eventName', headerName: 'Event Name', width: 200 },
        { field: 'eventStartDate', headerName: 'Start Date', width: 130 },
        { field: 'eventEndDate', headerName: 'End Date', width: 130 },
        { field: 'location', headerName: 'Location', width: 130 },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 150,
            sortable: false,
            renderCell: (params) => (
                <div>
                    <IconButton
                        color="primary"
                        onClick={() => handleView(params.row.id)}
                    >
                        <VisibilityIcon />
                    </IconButton>
                    <IconButton
                        color="secondary"
                        onClick={() => handleEdit(params.row)}
                    >
                        <EditIcon />
                    </IconButton>
                    <IconButton
                        color="error"
                        onClick={() => handleDelete(params.row.id)}
                    >
                        <DeleteIcon />
                    </IconButton>
                </div>
            ),
        },
    ];

    const handleView = (id) => {
        console.log('View event', id);
        navigate(`/events/${id}`);

        // Implement your view logic here
    };

    const handleEdit = (eventData) => {
        navigate(`/edit-event/${eventData.id}`, { 
            state: { 
                authToken: token, 
                parentEventId: eventData.parentEventId,
                eventId: eventData.id 
            } 
        });
        // Implement your edit logic here
    };

    

    const handleDelete = (id) => {
        console.log('Delete event', id);
        // Implement your delete logic here
    };

    useEffect(() => {
        if (token) {
            axios.get(`${config.BASE_URL_SPORTS_MANAGEMENT_SERVICE}/event?fullDetails=false`, {
                headers: {
                    Authorization: `${token}`
                }
            })
                .then(response => {
                    console.log(response.data.data.eventList);
                    setEvents(response.data.data.eventList);
                })
                .catch(error => {
                    console.error('Error fetching events:', error);
                });
        }
    }, [authToken]);

    return (
        <div style={{ padding: '40px' }}>
            <h1>Events</h1>
            <DataGrid rows={events} columns={columns} pageSize={5} />
        </div>
    );
};

export default EventList;