import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Button, TextField, Typography, MenuItem } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import config from '../config/apiConfig';
import { useNavigate, useLocation } from 'react-router-dom';

const AddPlayer = () => {
    const [isUploading, setIsUploading] = useState(false);
    const [player, setPlayer] = useState(false);
    const [sportsCategories, setSportsCategories] = useState([]);
    const token = localStorage.getItem('Authorization'); // Retrieve token
    const navigate = useNavigate();
    const location = useLocation();
    const { playerId } = location.state || {};
    const pictureFields = ['profilePicture', 'frontPlayerPicture', 'leftPlayerPicture', 'rightPlayerPicture'];

    console.log(playerId)
    const [initialValues, setInitialValues] = useState({
        id: null,
        email: null,
        firstName: '',
        lastName: '',
        dateOfBirth: '',
        representingCountry: '',
        nationality: '',
        sportsCategoryId: '',
        gender: '',
        profilePicture: null,
        frontPlayerPicture: null,
        leftPlayerPicture: null,
        rightPlayerPicture: null,
        playerAttributeList: []
    });

    const validationSchema = Yup.object().shape({
        firstName: Yup.string().required('First name is required'),
        lastName: Yup.string().required('Last name is required'),
        representingCountry: Yup.string().required('Representing country is required'),
        nationality: Yup.string().required('Nationality is required'),
        sportsCategoryId: Yup.string().required('Sports category ID is required'),
        gender: Yup.string().oneOf(['MALE', 'FEMALE', 'OTHER'], 'Select a valid gender').required('Gender is required'),
        profilePicture: Yup.mixed().required('Profile picture is required'),
        frontPlayerPicture: Yup.mixed().required('Front player picture is required'),
        leftPlayerPicture: Yup.mixed().required('Left player picture is required'),
        rightPlayerPicture: Yup.mixed().required('Right player picture is required'),
    });

    useEffect(() => {
        const fetchSportsCategories = async () => {
            try {
                
                const response = await axios.get(`${config.BASE_URL_SPORTS_MANAGEMENT_SERVICE}/sportsCategory`, {
                    headers: {
                        Authorization: `${token}`,
                    },
                });
                const categories = response.data.data?.sportsCategoryList || [];
                setSportsCategories(categories);
            } catch (error) {
                console.error('Error fetching sports categories:', error);
                setSportsCategories([]);
            }
        };

        const fetchPlayer = async () => {
            if (!playerId) return;

            try {
                const response = await axios.get(
                    `${config.BASE_URL_PLAYER_MANAGEMENT_SERVICE}/player/updatePlayer?playerId=${playerId}`,
                    {
                        headers: {
                            Authorization: `${token}`,
                        },
                    }
                );
                const player = response.data.data;

                if (player) {
                    console.log(player.playerAttributeList);
                    const formattedDateOfBirth = player.dateOfBirth
                        ? new Date(player.dateOfBirth).toISOString().split('T')[0]
                        : '';

                    setInitialValues((prevValues) => ({
                        ...prevValues,
                        id: player.id || '',
                        email: player.email || null,
                        firstName: player.firstName || '',
                        lastName: player.lastName || '',
                        dateOfBirth: formattedDateOfBirth || '',
                        representingCountry: player.representingCountry || '',
                        nationality: player.nationality || '',
                        sportsCategoryId: player.sportsCategory || '',
                        gender: player.gender || '',
                        profilePicture: player.playerProfilePicture || '',
                        frontPlayerPicture: player.frontPlayerPicture || '',
                        leftPlayerPicture: player.leftPlayerPicture || '',
                        rightPlayerPicture: player.rightPlayerPicture || '',
                        playerAttributeList: player.playerAttributeList || [],
                    }));
                }
            } catch (error) {
                console.error('Error fetching player details:', error);
            }
        };

        fetchSportsCategories();
        fetchPlayer();
    }, [token, playerId]);

    const getPresignedUrl = async (fileType) => {
        try {
            const response = await axios.post(
                `${config.BASE_URL_ASSET_MANAGEMENT_SERVICE}/api/v1/asset/getPreSignedUrl`,
                {
                    fileType: "PNG",
                    isProtected: false,
                    entityType: 'PLAYER',
                },
                {
                    headers: {
                        Authorization: `${token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            return response.data.data;
        } catch (error) {
            console.error('Error getting pre-signed URL:', error);
            return null;
        }
    };

    const uploadToS3 = async (url, file) => {
        try {
            await axios.put(url, file, {
                headers: {
                    'x-amz-acl': 'public-read',
                    'Content-Type': file.type,
                },
            });
            console.log('File uploaded successfully');
            return true;
        } catch (error) {
            console.error('Error uploading file:', error);
            return false;
        }
    };

    const handleAddPlayer = async (values) => {
        const { profilePicture, frontPlayerPicture, leftPlayerPicture, rightPlayerPicture, ...playerData } = values;

        if (!profilePicture || !frontPlayerPicture || !leftPlayerPicture || !rightPlayerPicture) {
            alert('Please select all required pictures');
            return;
        }

        setIsUploading(true);

        // Get presigned URLs and fileUUIDs for each image
        const presignedData = await Promise.all([
            getPresignedUrl(profilePicture.type.split('/')[1]),
            getPresignedUrl(frontPlayerPicture.type.split('/')[1]),
            getPresignedUrl(leftPlayerPicture.type.split('/')[1]),
            getPresignedUrl(rightPlayerPicture.type.split('/')[1]),
        ]);

        // Validate presigned data
        if (presignedData.includes(null)) {
            setIsUploading(false);
            alert('Failed to get presigned URLs');
            return;
        }

        const presignedUrls = presignedData.map(data => data.preSignedUrl);
        const fileUUIDs = presignedData.map(data => data.fileUUID);

        // Upload images to S3
        const uploadSuccess = await Promise.all([
            uploadToS3(presignedUrls[0], profilePicture),
            uploadToS3(presignedUrls[1], frontPlayerPicture),
            uploadToS3(presignedUrls[2], leftPlayerPicture),
            uploadToS3(presignedUrls[3], rightPlayerPicture),
        ]);

        if (uploadSuccess.includes(false)) {
            setIsUploading(false);
            alert('Failed to upload one or more profile pictures');
            return;
        }

        const playerPayload = {
            ...playerData,
            playerProfilePicture: {
                assetTitle: `${values.firstName} ${values.lastName}`,
                assetDescription: `${values.firstName} ${values.lastName} Profile Picture`,
                fileLink: presignedUrls[0].split('?')[0],
                fileUuid: fileUUIDs[0], // Correctly set fileUUID
                fileType: profilePicture.type.split('/')[1].toUpperCase(),
                isProtected: false,
            },
            frontPlayerPicture: {
                assetTitle: `${values.firstName} ${values.lastName}`,
                assetDescription: `${values.firstName} ${values.lastName} Front Profile Picture`,
                fileLink: presignedUrls[1].split('?')[0],
                fileUuid: fileUUIDs[1], // Correctly set fileUUID
                fileType: frontPlayerPicture.type.split('/')[1].toUpperCase(),
                isProtected: false,
            },
            leftPlayerPicture: {
                assetTitle: `${values.firstName} ${values.lastName}`,
                assetDescription: `${values.firstName} ${values.lastName} Left Profile Picture`,
                fileLink: presignedUrls[2].split('?')[0],
                fileUuid: fileUUIDs[2], // Correctly set fileUUID
                fileType: leftPlayerPicture.type.split('/')[1].toUpperCase(),
                isProtected: false,
            },
            rightPlayerPicture: {
                assetTitle: `${values.firstName} ${values.lastName}`,
                assetDescription: `${values.firstName} ${values.lastName} Right Profile Picture`,
                fileLink: presignedUrls[3].split('?')[0],
                fileUuid: fileUUIDs[3], // Correctly set fileUUID
                fileType: rightPlayerPicture.type.split('/')[1].toUpperCase(),
                isProtected: false,
            },
        };

        try {
            const response = await axios.post(`${config.BASE_URL_PLAYER_MANAGEMENT_SERVICE}/player`, playerPayload, {
                headers: {
                    Authorization: `${token}`,
                    'Content-Type': 'application/json',
                },
            });
            if (response.status === 200 && response.data.code === "SP-00") {
                navigate('/Dashboard', { state: { tabValue: 2 } });
                alert('Player added successfully!');
            }
        } catch (error) {
            console.error('Error adding player:', error);
            alert('Failed to add player');
        }
        setIsUploading(false);
    };

    const handleEditPlayer = async (values) => {
        const { profilePicture, frontPlayerPicture, leftPlayerPicture, rightPlayerPicture, ...playerData } = values;

        // Get existing player data (e.g., from a state or props, like `initialValues`)
        const existingPlayer = initialValues;  // Assuming `initialValues` contains current player data

        // Check if any images were changed
        const imagesToUpload = [
            profilePicture !== existingPlayer.profilePicture && profilePicture,
            frontPlayerPicture !== existingPlayer.frontPlayerPicture && frontPlayerPicture,
            leftPlayerPicture !== existingPlayer.leftPlayerPicture && leftPlayerPicture,
            rightPlayerPicture !== existingPlayer.rightPlayerPicture && rightPlayerPicture
        ].filter(Boolean);  // Filter out unchanged images
        console.log("imagesToUpload");

        console.log(imagesToUpload);
        let presignedUrls = [];
        let fileUUIDs = [];

        // If images were changed, get presigned URLs and upload images
        if (imagesToUpload.length > 0) {
            const presignedData = await Promise.all(imagesToUpload.map(image =>
                getPresignedUrl(image.type.split('/')[1])  // Get presigned URLs for new images
            ));

            if (presignedData.includes(null)) {
                setIsUploading(false);
                alert('Failed to get presigned URLs');
                return;
            }

            presignedUrls = presignedData.map(data => data.preSignedUrl);
            fileUUIDs = presignedData.map(data => data.fileUUID);

            // Upload the new images to S3
            const uploadSuccess = await Promise.all(imagesToUpload.map((image, index) =>
                uploadToS3(presignedUrls[index], image)
            ));

            if (uploadSuccess.includes(false)) {
                setIsUploading(false);
                alert('Failed to upload one or more profile pictures');
                return;
            }
        }

        // Construct the updated player data payload
        const playerPayload = {
            id: existingPlayer.id,  // Existing player ID to edit
            email: existingPlayer.email,  // Preserving current email if it's not updated
            firstName: values.firstName,
            lastName: values.lastName,
            dateOfBirth: values.dateOfBirth,
            nationality: values.nationality,
            representingCountry: values.representingCountry,
            teamId: existingPlayer.teamId,  // Preserving existing team if not updated
            rating: values.rating,
            playerRank: values.playerRank,
            gender: values.gender,
            playerProfilePicture: profilePicture !== existingPlayer.profilePicture ? {
                assetTitle: `${values.firstName} ${values.lastName}`,
                assetDescription: `${values.firstName} ${values.lastName} Profile Picture`,
                fileLink: presignedUrls[0] ? presignedUrls[0].split('?')[0] : existingPlayer.playerProfilePicture.fileLink,
                fileUuid: fileUUIDs[0] || existingPlayer.playerProfilePicture.fileUuid,
                fileType: profilePicture?.type.split('/')[1].toUpperCase(),
                isProtected: false,
                hashtags: [],
            } : existingPlayer.playerProfilePicture,

            frontPlayerPicture: frontPlayerPicture !== existingPlayer.frontPlayerPicture ? {
                assetTitle: `${values.firstName} ${values.lastName}`,
                assetDescription: `${values.firstName} ${values.lastName} Front Profile Picture`,
                fileLink: presignedUrls[1] ? presignedUrls[1].split('?')[0] : existingPlayer.frontPlayerPicture.fileLink,
                fileUuid: fileUUIDs[1] || existingPlayer.frontPlayerPicture.fileUuid,
                fileType: frontPlayerPicture?.type.split('/')[1].toUpperCase(),
                isProtected: false,
                hashtags: [],
            } : existingPlayer.frontPlayerPicture,

            leftPlayerPicture: leftPlayerPicture !== existingPlayer.leftPlayerPicture ? {
                assetTitle: `${values.firstName} ${values.lastName}`,
                assetDescription: `${values.firstName} ${values.lastName} Left Profile Picture`,
                fileLink: presignedUrls[2] ? presignedUrls[2].split('?')[0] : existingPlayer.leftPlayerPicture.fileLink,
                fileUuid: fileUUIDs[2] || existingPlayer.leftPlayerPicture.fileUuid,
                fileType: leftPlayerPicture?.type.split('/')[1].toUpperCase(),
                isProtected: false,
                hashtags: [],
            } : existingPlayer.leftPlayerPicture,

            rightPlayerPicture: rightPlayerPicture !== existingPlayer.rightPlayerPicture ? {
                assetTitle: `${values.firstName} ${values.lastName}`,
                assetDescription: `${values.firstName} ${values.lastName} Right Profile Picture`,
                fileLink: presignedUrls[3] ? presignedUrls[3].split('?')[0] : existingPlayer.rightPlayerPicture.fileLink,
                fileUuid: fileUUIDs[3] || existingPlayer.rightPlayerPicture.fileUuid,
                fileType: rightPlayerPicture?.type.split('/')[1].toUpperCase(),
                isProtected: false,
                hashtags: [],
            } : existingPlayer.rightPlayerPicture,

            playerAttributeList: values.playerAttributeList,  // Assuming attributes can also be updated
        };

        try {
            const response = await axios.put(`${config.BASE_URL_PLAYER_MANAGEMENT_SERVICE}/player`, playerPayload, {
                headers: {
                    Authorization: `${token}`,  // Authorization token
                    'Content-Type': 'application/json',
                },
            });

            if (response.status === 200 && response.data.code === "SP-00") {
                navigate('/Dashboard', { state: { tabValue: 2 } });
                alert('Player updated successfully!');
            }
        } catch (error) {
            console.error('Error editing player:', error);
            alert('Failed to update player');
        }

        setIsUploading(false);
    };

    return (
        <Box sx={{ padding: 3 }}>
            <Typography variant="h4" gutterBottom>
                Add Player
            </Typography>

            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={values => {
                    if (playerId === undefined) {
                        handleAddPlayer(values);
                    } else {
                        handleEditPlayer(values);
                    }
                }}
            >
                {({ values, errors, touched, setFieldValue, handleSubmit }) => (
                    <Form onSubmit={handleSubmit}>
                        <Field
                            as={TextField}
                            name="firstName"
                            label="First Name"
                            fullWidth
                            error={touched.firstName && !!errors.firstName}
                            helperText={touched.firstName && errors.firstName}
                            sx={{ mb: 2 }}
                        />
                        <Field
                            as={TextField}
                            name="lastName"
                            label="Last Name"
                            fullWidth
                            error={touched.lastName && !!errors.lastName}
                            helperText={touched.lastName && errors.lastName}
                            sx={{ mb: 2 }}
                        />
                        <Field
                            as={TextField}
                            name="dateOfBirth"
                            label="Date of Birth"
                            type="date"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            error={touched.dateOfBirth && !!errors.dateOfBirth}
                            helperText={touched.dateOfBirth && errors.dateOfBirth}
                            sx={{ mb: 2 }}
                        />
                        <Field
                            as={TextField}
                            name="representingCountry"
                            label="Representing Country"
                            fullWidth
                            error={touched.representingCountry && !!errors.representingCountry}
                            helperText={touched.representingCountry && errors.representingCountry}
                            sx={{ mb: 2 }}
                        />
                        <Field
                            as={TextField}
                            name="nationality"
                            label="Nationality"
                            fullWidth
                            error={touched.nationality && !!errors.nationality}
                            helperText={touched.nationality && errors.nationality}
                            sx={{ mb: 2 }}
                        />
                        <Field
                            as={TextField}
                            name="sportsCategoryId"
                            label="Sports Category"
                            select
                            fullWidth
                            error={touched.sportsCategoryId && !!errors.sportsCategoryId}
                            helperText={touched.sportsCategoryId && errors.sportsCategoryId}
                            sx={{ mb: 2 }}
                        >
                            {sportsCategories.map((sportsCategory) => (
                                <MenuItem key={sportsCategory.id} value={sportsCategory.id}>
                                    {sportsCategory.categoryName}
                                </MenuItem>
                            ))}
                        </Field>

                        <Field
                            as={TextField}
                            name="gender"
                            label="Gender"
                            select
                            fullWidth
                            error={touched.gender && !!errors.gender}
                            helperText={touched.gender && errors.gender}
                            sx={{ mb: 2 }}
                        >
                            <MenuItem value="MALE">Male</MenuItem>
                            <MenuItem value="FEMALE">Female</MenuItem>
                            <MenuItem value="OTHER">Other</MenuItem>
                        </Field>

                        {/* Profile Pictures */}
                        <div className='player-images'>
                            {initialValues.profilePicture === null && (
                                <Box sx={{ mb: 2 }}>
                                    <Field
                                        name="profilePicture"
                                        render={() => (
                                            <Button variant="contained" component="label" fullWidth>
                                                Upload Profile Picture
                                                <input
                                                    accept="image/*"
                                                    type="file"
                                                    hidden
                                                    onChange={(event) => {
                                                        setFieldValue('profilePicture', event.target.files[0]);
                                                    }}
                                                />
                                            </Button>
                                        )}
                                    />
                                    {touched.profilePicture && errors.profilePicture && (
                                        <Typography color="error">{errors.profilePicture}</Typography>
                                    )}
                                </Box>
                            )}

                            {initialValues.profilePicture && (
                                <Box sx={{ mt: 2 }}>
                                    <Typography variant="body1">Profile Picture</Typography>
                                    <img
                                        src={initialValues.profilePicture.fileLink}
                                        alt="Profile Preview"
                                    />
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={() => {
                                            // Trigger file input change to update the image
                                            document.getElementById('profilePictureInput').click();
                                        }}
                                        sx={{ mt: 1 }}
                                    >
                                        Change Image
                                    </Button>
                                    <input
                                        id="profilePictureInput"
                                        accept="image/*"
                                        type="file"
                                        hidden
                                        onChange={(event) => {
                                            setFieldValue('profilePicture', event.target.files[0]);
                                        }}
                                    />
                                </Box>
                            )}

                            {initialValues.frontPlayerPicture === null && (
                                <Box sx={{ mb: 2 }}>
                                    <Field
                                        name="frontPlayerPicture"
                                        render={() => (
                                            <Button variant="contained" component="label" fullWidth>
                                                Upload Front Picture
                                                <input
                                                    accept="image/*"
                                                    type="file"
                                                    hidden
                                                    onChange={(event) => {
                                                        setFieldValue('frontPlayerPicture', event.target.files[0]);
                                                    }}
                                                />
                                            </Button>
                                        )}
                                    />
                                    {touched.frontPlayerPicture && errors.frontPlayerPicture && (
                                        <Typography color="error">{errors.frontPlayerPicture}</Typography>
                                    )}
                                </Box>
                            )}

                            {initialValues.frontPlayerPicture && (
                                <Box sx={{ mt: 2 }}>
                                    <Typography variant="body1">Front Picture</Typography>
                                    <img
                                        src={initialValues.frontPlayerPicture.fileLink}
                                        alt="Front Preview"
                                    />
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={() => {
                                            document.getElementById('frontPlayerPictureInput').click();
                                        }}
                                        sx={{ mt: 1 }}
                                    >
                                        Change Image
                                    </Button>
                                    <input
                                        id="frontPlayerPictureInput"
                                        accept="image/*"
                                        type="file"
                                        hidden
                                        onChange={(event) => {
                                            setFieldValue('frontPlayerPicture', event.target.files[0]);
                                        }}
                                    />
                                </Box>
                            )}

                            {initialValues.leftPlayerPicture === null && (
                                <Box sx={{ mb: 2 }}>
                                    <Field
                                        name="leftPlayerPicture"
                                        render={() => (
                                            <Button variant="contained" component="label" fullWidth>
                                                Upload Left Picture
                                                <input
                                                    accept="image/*"
                                                    type="file"
                                                    hidden
                                                    onChange={(event) => {
                                                        setFieldValue('leftPlayerPicture', event.target.files[0]);
                                                    }}
                                                />
                                            </Button>
                                        )}
                                    />
                                    {touched.leftPlayerPicture && errors.leftPlayerPicture && (
                                        <Typography color="error">{errors.leftPlayerPicture}</Typography>
                                    )}
                                </Box>
                            )}

                            {initialValues.leftPlayerPicture && (
                                <Box sx={{ mt: 2 }}>
                                    <Typography variant="body1">Left Picture</Typography>
                                    <img
                                        src={initialValues.leftPlayerPicture.fileLink}
                                        alt="Left Preview"
                                    />
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={() => {
                                            document.getElementById('leftPlayerPictureInput').click();
                                        }}
                                        sx={{ mt: 1 }}
                                    >
                                        Change Image
                                    </Button>
                                    <input
                                        id="leftPlayerPictureInput"
                                        accept="image/*"
                                        type="file"
                                        hidden
                                        onChange={(event) => {
                                            setFieldValue('leftPlayerPicture', event.target.files[0]);
                                        }}
                                    />
                                </Box>
                            )}

                            {initialValues.rightPlayerPicture === null && (
                                <Box sx={{ mb: 2 }}>
                                    <Field
                                        name="rightPlayerPicture"
                                        render={() => (
                                            <Button variant="contained" component="label" fullWidth>
                                                Upload Right Picture
                                                <input
                                                    accept="image/*"
                                                    type="file"
                                                    hidden
                                                    onChange={(event) => {
                                                        setFieldValue('rightPlayerPicture', event.target.files[0]);
                                                    }}
                                                />
                                            </Button>
                                        )}
                                    />
                                    {touched.rightPlayerPicture && errors.rightPlayerPicture && (
                                        <Typography color="error">{errors.rightPlayerPicture}</Typography>
                                    )}
                                </Box>
                            )}

                            {initialValues.rightPlayerPicture && (
                                <Box sx={{ mt: 2 }}>
                                    <Typography variant="body1">Right Picture</Typography>
                                    <img
                                        src={initialValues.rightPlayerPicture.fileLink}
                                        alt="Right Preview"
                                    />
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={() => {
                                            document.getElementById('rightPlayerPictureInput').click();
                                        }}
                                        sx={{ mt: 1 }}
                                    >
                                        Change Image
                                    </Button>
                                    <input
                                        id="rightPlayerPictureInput"
                                        accept="image/*"
                                        type="file"
                                        hidden
                                        onChange={(event) => {
                                            setFieldValue('rightPlayerPicture', event.target.files[0]);
                                        }}
                                    />
                                </Box>
                            )}
                        </div>
                        <div className='player-stats'>
                        {initialValues.playerAttributeList.length > 0 && initialValues.playerAttributeList.map((attribute, index) => (
                            <Box key={index} sx={{ mb: 2 }}>
                                <Field
                                    as={TextField}
                                    name={`playerAttributeList[${index}].attributeValue`}  // Dynamically name the field based on index
                                    label={attribute.attributeKey}  // Use the attributeKey as the label
                                    fullWidth
                                    value={values.playerAttributeList[index]?.attributeValue || ''}  // Default to empty string if undefined
                                    error={touched.playerAttributeList && touched.playerAttributeList[index] && !!errors.playerAttributeList?.[index]?.attributeValue}
                                    helperText={
                                        touched.playerAttributeList && touched.playerAttributeList[index] && errors.playerAttributeList?.[index]?.attributeValue
                                            ? errors.playerAttributeList[index]?.attributeValue
                                            : ''
                                    }
                                    onChange={(e) => {
                                        setFieldValue(`playerAttributeList[${index}].attributeValue`, e.target.value);
                                    }}
                                />
                            </Box>
                        ))}</div>
                        {/* Submit */}
                        {playerId !== undefined && (
                            <Box sx={{ mt: 10 }}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={isUploading}
                                    fullWidth
                                >
                                    {isUploading ? 'Uploading...' : 'Edit Player'}
                                </Button>
                            </Box>
                        )}

                        {playerId === undefined && (
                            <Box sx={{ mt: 10 }}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={isUploading}
                                    fullWidth
                                >
                                    {isUploading ? 'Uploading...' : 'Add Player'}
                                </Button>
                            </Box>
                        )}
                    </Form>
                )}
            </Formik>
        </Box>
    );
};

export default AddPlayer;
