import React, { useState } from 'react';
import { Tabs, Tab, Box, Paper } from '@mui/material';
import EventList from './EventList';
import TeamList from './TeamList';
import PlayerList from './PlayerList';
import { useLocation } from 'react-router-dom';

const Dashboard = () => {
    const location = useLocation();
    const tabValue = location.state?.tabValue ?? 0;
    const [value, setValue] = useState(tabValue);
    const [token, setToken] = useState('');
    const [authToken, setAuthToken] = useState('');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{typography: 'body1', p: 3, bgcolor: '#f4f6f8', minHeight: '100vh' }}>
            <Paper elevation={3} sx={{ p: 2, borderRadius: '10px', bgcolor: 'white' }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="dashboard tabs"
                    centered
                    textColor="primary"
                    indicatorColor="primary"
                    sx={{
                        borderBottom: 1,
                        borderColor: 'divider',
                        mb: 3,
                        '& .MuiTab-root': {
                            fontWeight: 'bold',
                            textTransform: 'none',
                            minWidth: '120px',
                        },
                    }}
                >
                    <Tab label="Events" />
                    <Tab label="Teams" />
                    <Tab label="Players" />
                </Tabs>

                <Box sx={{ p: 3 }}>
                    {value === 0 && <EventList authToken={authToken} />}
                    {value === 1 && <TeamList authToken={authToken} />}
                    {value === 2 && <PlayerList authToken={authToken} />}
                </Box>
            </Paper>
        </Box>
    );
};

export default Dashboard;
