// App.js
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Dashboard from './components/Dashboard';
import Login from './components/Login';
import EventList from './components/EventList';
import EventDetail from './components/EventDetail';
import EventForm from './components/EventForm';
import AddEvent from './components/EventForm';
import AddTeam from './components/TeamForm';
import PlayerForm from './components/PlayerForm';

import './App.css';

const App = () => {
    return (
        <div>
            <Routes>
                <Route path="/events" element={<EventList />} />
                <Route path="/" element={<Login />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/events/:id" element={<EventDetail />} />
                <Route path="/add-event" element={<AddEvent />} />
                <Route path="/add-team" element={<AddTeam />} />
                <Route path="/add-player" element={<PlayerForm />} />
                <Route path="/edit-player/:playerId" element={<PlayerForm />} />
                <Route path="/edit-event/:eventId" element={<EventForm />} />
            </Routes>
        </div>
    );
};

export default App;
