
const config = {
    // BASE_URL_USER_MANAGEMENT_SERVICE: 'http://localhost:8080',
    // BASE_URL_SPORTS_MANAGEMENT_SERVICE: 'http://localhost:8081',
    // BASE_URL_ASSET_MANAGEMENT_SERVICE: 'http://localhost:8000',
    // BASE_URL_PLAYER_MANAGEMENT_SERVICE: 'http://localhost:8083',
    // BASE_URL_TEAM_MANAGEMENT_SERVICE: 'http://localhost:8084',
    // BASE_URL_POINTS_MANAGEMENT_SERVICE: 'http://localhost:8085'

    BASE_URL_USER_MANAGEMENT_SERVICE: 'https://api.sportainment.live',
    BASE_URL_SPORTS_MANAGEMENT_SERVICE: 'https://api.sportainment.live',
    BASE_URL_ASSET_MANAGEMENT_SERVICE: 'https://api.sportainment.live',
    BASE_URL_PLAYER_MANAGEMENT_SERVICE: 'https://api.sportainment.live',
    BASE_URL_TEAM_MANAGEMENT_SERVICE: 'https://api.sportainment.live',
    BASE_URL_POINTS_MANAGEMENT_SERVICE: 'https://api.sportainment.live'
    // Add other URLs or settings here
};

export default config;
